<template>
  <v-app id="app">
    <!-- <v-main :id="isMobileFormat ? 'adnm-iphone container' : 'container'"> -->

    <v-main id="container">
      <MobileView style="z-index: 1" :show="isMobileFormat">
        <div id="adnami-lb1" style="max-width:unset;">
          <ins
            v-if="isTopscroll || isSkin || allEmpty"
            :style="computedStyle"
            :data-adsm-cc="guid"
            data-adsm-click=""
          ></ins>
        </div>
        <div class="wrapperss centerAlign">
          <v-row align="center">
            <ins
              v-if="isMobTop"
              style="display:inline-block"
              :data-adsm-cc="guid"
              data-adsm-click=""
            ></ins>

            <v-col
              class="d-flex"
              cols="12"
              :md="isMobileFormat ? 12 : 4"
              sm="12"
              v-for="item in 3"
              :key="item"
            >
              <!-- <NewsSnippet /> -->
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12">
              <ArticleFeature
                width="88vw"
                :isMobileFormat="isMobileFormat"
                :withColor="withColor"
                img="Image01.jpg"
              />
            </v-col>
          </v-row>
          <ins
            v-if="isExpander"
            style="display:inline-block;width:930px;height:180px"
            :data-adsm-cc="guid"
            data-adsm-click=""
            data-adsm-flex="16:9"
          ></ins>
          <ins
            v-if="isInterscroll"
            style="display:inline-block;width:930px;height:600px"
            :data-adsm-cc="guid"
            data-adsm-click=""
          ></ins>
          <ins
            v-if="isMobMid"
            style="display:inline-block;width:930px;height:600px"
            :data-adsm-cc="guid"
            data-adsm-click=""
          ></ins>
          <br />
          <v-row>
            <v-col cols="12" :md="isMobileFormat ? 12 : 8" sm="12">
              <ArticlePreview
                width="88vw"
                :withColor="withColor"
                :big="true"
                imgHeight="400"
                img="Image03.jpg"
              />
            </v-col>
            <v-col cols="12" :md="isMobileFormat ? 12 : 4" sm="12">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <ArticlePreview
                    width="88vw"
                    :withColor="withColor"
                    imgHeight="150"
                    img="Image02.jpg"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <ArticlePreview
                    width="88vw"
                    :withColor="withColor"
                    imgHeight="150"
                    img="Image04.jpg"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-8" cols="12" md="12" sm="12"
              ><FullWidhFeature :withColor="withColor" img="Image05.jpg" />
            </v-col>
            <v-col class="my-8" cols="12" md="12" sm="12">
              <FullWidhFeature
                :withColor="withColor"
                :rigth="true"
                img="Image06.jpg"
            /></v-col>
          </v-row>
          <v-sheet height="200px"></v-sheet>
        </div>
      </MobileView>
      <!-- @deviceChange="changeDevice" -->
      <Footer
        style="z-index: 10"
        :isMobile="isMobileFormat"
        @toogleColor="toogleColor"
      ></Footer>
    </v-main>
  </v-app>
</template>

<script>
// import NewsSnippet from "./../components/newsSnippet";
import ArticlePreview from "./../components/articlePreview";
import ArticleFeature from "./../components/articleFeature";
import FullWidhFeature from "./../components/fullwidthFeature";
import MobileView from "./../components/mobileView";
import Footer from "./../layout/footer";

const {
  expanders,
  mobTopScrolls,
  mobMidscrolls,
  skins,
  topscrolls,
  interscrolls,
  fullWidthInterscrolls,
  mobileInterscrolls,
  mobAchors,
  deskTakeover
} = require("../helpers/creatives");
export default {
  components: {
    MobileView,
    Footer,
    // NewsSnippet,
    ArticlePreview,
    FullWidhFeature,
    ArticleFeature,
  },
  mounted() {
    this.guidInUrl = this.urlParams.get("guid");
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://macro.adnami.io/macro/gen/adsm.ads.js"
    );
    let recaptchaScript2 = document.createElement("script");
    recaptchaScript2.setAttribute(
      "src",
      "https://macro.adnami.io/macro/spec/adsm.macro.a5752ea3-b1d0-4c41-a5ba-2d99ca9740b8.js"
      // "https://macro.adnami.io/macro/spec/adsm.macro.94c6ef1e-e14a-4875-92e1-358ac24103fa.js"
    );
    document.head.appendChild(recaptchaScript);
    document.head.appendChild(recaptchaScript2);
  },
  data() {
    return {
      withColor: true,
      guidInUrl: null,
      // mobileDevice: false,
      mobTopScrolls,
      mobMidscrolls,
      skins,
      topscrolls,
      interscrolls,
      fullWidthInterscrolls,
      mobileInterscrolls,
  mobAchors,
  deskTakeover,
      expanders,
      desktop: {
        defaultSkin: "741f9f17-6eae-4577-b98f-0ddcbb96359a",
        defaultTopScroll: "805a48c1-4088-458d-982b-96461a70750a",
        defaultInterscroll: "c250f745-b35e-45d9-b035-1d8b6fdabc63",
        defaultInreadExpander: "671fe1c6-3885-4221-b195-e04c56f7865a",
        defaultTakeover: '2e69bc6b-f971-4c6f-b8ab-79b10c7d8aaf'
      },
      mobile: {
        defaultTop: "d8e433a2-f087-41f6-af54-1abfb68eaba9",
        defaultMid: "d8e433a2-f087-41f6-af54-1abfb68eaba9",
        defaultAchor: '41611055-7552-4152-83b5-fe5a994d44f3'
      },
    };
  },
  methods: {
    // changeDevice(device) {
    //   console.log(device);

    //   this.mobileDevice = device === "mobile-phone";
    // },
    toogleColor() {
      this.withColor = !this.withColor;
    },
    goToCat({ guid, type }) {
      window.location.href = `${window.location.pathname}?${type}&guid=${guid}`;
    },
  },
  computed: {
    urlParams() {
      let uri = window.location.search.substring(1);
      return new URLSearchParams(uri);
    },
    isTopscroll() {
      return this.urlParams.get("topscroll") !== null;
    },
    isSkin() {
      return this.urlParams.get("skin") !== null;
    },
    isMobTop() {
      return this.urlParams.get("mob-top") !== null;
    },
    isMobMid() {
      return this.urlParams.get("mob-mid") !== null;
    },
    isInterscroll() {
      return this.urlParams.get("interscroll") !== null;
    },
    isExpander() {
      return this.urlParams.get("expander") !== null;
    },
    isTakeover() {
      return this.urlParams.get("takeover") !== null;
    },
    isMobAchor() {
      return this.urlParams.get("anchor") !== null;
    },
    isMobileFormat() {
      // return this.mobileDevice;
      return this.isMobTop || this.isMobMid || this.isMobAchor;
    },
    allEmpty() {
      return (
        !this.isTopscroll &&
        !this.isSkin &&
        !this.isInterscroll &&
        !this.isMobMid &&
        !this.isMobTop &&
        !this.isTakeover &&
        !this.isMobAchor &&
        !this.isExpander
      );
    },
    computedStyle() {
      let width = null;
      let height = null;
      if (this.isSkin || this.isTopscroll) {
        width = "930px";
      }

      if (this.isSkin) {
        height = "160px";
      }

      return {
        display: "inline-block",
        width,
        height,
        //width: this.isSkin ? "930px": null,
        //height: this.isSkin ? "600px" : null,
      };
    },
    guid() {
      let defaultGuid = this.desktop.defaultSkin;
      if (this.isSkin) defaultGuid = this.desktop.defaultSkin;
      if (this.isInterscroll) defaultGuid = this.desktop.defaultInterscroll;
      if (this.isExpander) defaultGuid = this.desktop.defaultInreadExpander;
      if (this.isTakeover) defaultGuid = this.desktop.defaultTakeover;
      if (this.isMobTop) defaultGuid = this.mobile.defaultTop;
      if (this.isMobMid) defaultGuid = this.mobile.defaultMid;
      if (this.isMobAchor) defaultGuid = this.mobile.defaultTop;
      if (this.isMobAchor) defaultGuid = this.mobile.defaultAchor;
      return this.guidInUrl || defaultGuid;
    },
  },
};
</script>
<style>
@import "./../styles/style.css";
</style>

const mobTopScrolls = [
  {
    name: "HBO",
    value: {
      type: "mob-top",
      guid: "cde09b77-b850-45bf-85a7-b4b6663d39dd",
    },
  },
];
const mobMidscrolls = [
  {
    name: "Hellboy",
    value: {
      type: "mob-mid",
      guid: "b3b5a418-fc72-45c4-8a8c-14d2f88eedbe",
    },
  },
];
const mobAchors = [
  { name: 'VW', value: { type: 'anchor', guid: '41611055-7552-4152-83b5-fe5a994d44f3' } }
]
const deskTakeover = [
  { name: 'PING', value: { type: 'takeover', guid: '2e69bc6b-f971-4c6f-b8ab-79b10c7d8aaf' } }
]
const skins = [
  {
    name: "Joker",
    value: { type: "skin", guid: "741f9f17-6eae-4577-b98f-0ddcbb96359a" },
  },
  {
    name: "Miele",
    value: { type: "skin", guid: "afd9c736-6e54-435c-bbf8-dcdb5daec78b" },
  },
  {
    name: "Unibet",
    value: { type: "skin", guid: "24319ae7-28ac-4364-b149-8ba9d52d7a80" },
  },
  {
    name: "Urban Gym",
    value: { type: "skin", guid: "ca88592a-9ab0-40ee-97cc-ea9f8d0e8181" },
  },
  {
    name: "HBO ",
    value: { type: "skin", guid: "e9480db5-0ba4-4f51-962d-879a2a487cc1" },
  },
  {
    name: "Premier League",
    value: { type: "skin", guid: "c4f43da2-1f9e-48d4-bc07-a4f436aff9af" },
  },
  {
    name: "Cocio",
    value: { type: "skin", guid: "5ac17298-580f-4531-9fa9-93c5902b6ded" },
  },
  {
    name: "Tulip",
    value: { type: "skin", guid: "aa7eae7b-2cdc-41aa-9ad3-3ea1b3b50654" },
  },
  {
    name: "Miele",
    value: { type: "skin", guid: "99aa80d9-4f9b-437e-8132-b96a4586d16e" },
  },
  {
    name: "Playstation",
    value: { type: "skin", guid: "4b8df62e-59d3-4151-bcbf-45dbf9e3c5fa" },
  },
  {
    name: "Meny",
    value: { type: "skin", guid: "bca07972-a5c9-4d21-93ed-87b24c13b12c" },
  },
  {
    name: "Toyota lexus",
    value: { type: "skin", guid: "cbd45552-5f8b-4bf3-8aec-8356030ab719" },
  },
  {
    name: "Kardashian",
    value: { type: "skin", guid: "788dae3e-ae1d-4b3f-9e41-8db9f5fa3816" },
  },
  {
    name: "Aldi",
    value: { type: "skin", guid: "70b15f9b-51a5-4875-aa9c-d22f8f47da42" },
  },
  {
    name: "Tesco",
    value: { type: "skin", guid: "65bea50f-dec9-4559-9553-a4043491abbb" },
  },
  {
    name: "Oister",
    value: { type: "skin", guid: "000fb1d3-56ad-497a-b544-39c6c49923d7" },
  },
  {
    name: "Lego: ",
    value: { type: "skin", guid: "5507c26f-5914-41d8-a6d1-9c66961f93f0" },
  },
  {
    name: "TV2 TOUR DE FRANCE",
    value: { type: "skin", guid: "c3eea815-d7cf-4f71-831b-af96dc1622e9" },
  },
  {
    name: "Playstation Ghost",
    value: { type: "skin", guid: "d8068218-7f60-460a-a5df-9442335b472f" },
  },
  {
    name: "P&G always: ",
    value: { type: "skin", guid: "b3392b7e-c3dc-47c9-9653-cfb70dede199" },
  },
  {
    name: "LEGO vehicles: ",
    value: { type: "skin", guid: "f50a8bdd-7b8d-442c-8e1d-54a67b0398d1" },
  },
  {
    name: "Norlys feb 2021: ",
    value: { type: "skin", guid: "b11a01ed-28c2-4446-bb8d-802947de4054" },
  },
  {
    name: 'TV2 play "Stormester"',
    value: { type: "skin", guid: "0cb02bd5-0d60-47aa-bc15-bc15b27fb7a0" },
  },
];

const topscrolls = [
  {
    name: "Stofa",
    value: {
      type: "topscroll",
      guid: "02e3ae50-fa1b-492f-8ccb-0714a6ef92c2",
    },
  },
  {
    name: "Stofa",
    value: {
      type: "topscroll",
      guid: "8eebd92f-97f0-4854-8aaa-9c51d1fc4f94",
    },
  },
  {
    name: "Fyrværkeri",
    value: {
      type: "topscroll",
      guid: "f0d2ff9b-eca2-484e-9e5a-b8cbd54f9c41",
    },
  },
  {
    name: "Corona",
    value: {
      type: "topscroll",
      guid: "29ccb8f9-efcf-4f68-95b4-3fb14ccb7a62",
    },
  },
  {
    name: "BMW",
    value: {
      type: "topscroll",
      guid: "d8cff142-27dc-405c-a6c8-8d3524292256",
    },
  },
  {
    name: "Mazda",
    value: {
      type: "topscroll",
      guid: "efe07013-37a4-4047-a1b7-4744891e6c56",
    },
  },
  {
    name: "HBO ",
    value: {
      type: "topscroll",
      guid:
        "762de0f2-4ff1-4009-a3dc-1a61aa6b1f9f&fbclid=IwAR3C5b5EnUp7OApbk1LwnTkiWReWN7NREfdomIRIZnDRpJraasL0XhqZEhQ",
    },
  },
  {
    name: "Kop & Kande",
    value: {
      type: "topscroll",
      guid: "ea50b25b-132d-4ff7-b5c5-2498aea192b3",
    },
  },
  {
    name: "The Gentleman",
    value: {
      type: "topscroll",
      guid: "ed07ef30-7d8f-4740-b8cf-f38e0d0014bb",
    },
  },
  {
    name: "Just eat",
    value: {
      type: "topscroll",
      guid: "ad253485-0ffb-4743-bdd9-f41491c7c415",
    },
  },
  {
    name: "De forbandede år (scanbox)",
    value: {
      type: "topscroll",
      guid: "f9e1b607-e3ff-4ef9-8723-74d1733327a3",
    },
  },
  {
    name: "EM2020",
    value: {
      type: "topscroll",
      guid:
        "12rT-OwdbI_FPlJE9EtsiwKbqEYPx6v6t/view?userstoinvite=jens@concept.dk&ts=5df774be&actionButton=1",
    },
  },
  {
    name: "DSB",
    value: {
      type: "topscroll",
      guid: "71dcf34d-8cc2-4ba2-a336-74a4c43e1b0f",
    },
  },
  {
    name: "Premier League",
    value: {
      type: "topscroll",
      guid: "7835acba-67d3-4ae8-9dc3-b88925f18efd",
    },
  },
  {
    name: "Arla foodfestival",
    value: {
      type: "topscroll",
      guid: "15a4df7e-58b0-483a-a368-1b79cd436dbb",
    },
  },
  {
    name: "The Gentleman: ",
    value: {
      type: "topscroll",
      guid: "ed07ef30-7d8f-4740-b8cf-f38e0d0014bb",
    },
  },
  {
    name: "Unibet ",
    value: {
      type: "topscroll",
      guid: "f6e7def9-8067-49e3-b3d5-d89129373208",
    },
  },
  {
    name: "MK (Kia motors) ",
    value: {
      type: "topscroll",
      guid: "7aed2b9d-c129-410f-a899-c2fe486e2519",
    },
  },
  {
    name: "Toyota",
    value: {
      type: "topscroll",
      guid: "cc9713b4-6a92-4b8a-a01d-75862069e8b9",
    },
  },
  {
    name: "Fritz Hansen",
    value: {
      type: "topscroll",
      guid: "10d724ad-4be0-42f8-995b-cf853f8dc862",
    },
  },
  {
    name: "Netto",
    value: {
      type: "topscroll",
      guid: "bff96725-6033-4790-8810-561f607291ff",
    },
  },
  {
    name: "Netto",
    value: {
      type: "topscroll",
      guid: "15c77f70-3c6f-4b7f-97c8-a0104030c3a2",
    },
  },
  {
    name: "Oister",
    value: {
      type: "topscroll",
      guid: "85c05fc0-f25b-44f1-8dd7-5debb2146ec0",
    },
  },
  {
    name: "TV2 play",
    value: {
      type: "topscroll",
      guid: "4f42ffb7-7a94-47a8-909e-ae99923a7c82",
    },
  },
  {
    name: "Lego",
    value: {
      type: "topscroll",
      guid: "7c842c2e-b9cd-4324-81b9-dc4cd8d22a7f",
    },
  },
  {
    name: "Lunarway: ",
    value: {
      type: "topscroll",
      guid: "2be22808-d1cd-4c14-aa76-56f645cbb555",
    },
  },
  {
    name: "Lunarway metalkort: ",
    value: {
      type: "topscroll",
      guid: "282de8b6-527e-4172-80cc-7592366dcdce",
    },
  },
  {
    name: "LEGO vehicles: ",
    value: {
      type: "topscroll",
      guid: "14549a97-ae4b-4482-ac99-50c91fd77873",
    },
  },
  {
    name: "Scanbox Familien Bigfoot",
    value: {
      type: "topscroll",
      guid: "c211b2c1-8046-4629-95e9-37c403eed2f2",
    },
  },
  {
    name: "Hyundai: ",
    value: {
      type: "topscroll",
      guid: "d3786146-4361-4c19-b517-d2f17dae4784",
    },
  },
  {
    name: "BAT lyft Tom & Petter (Sverige)",
    value: {
      type: "topscroll",
      guid: "274582cd-9501-42b8-b599-2d6d27b7eb86",
    },
  },
  {
    name: "BAT lyft Julian & Emilio sverige:",
    value: {
      type: "topscroll",
      guid: "f3deb852-5bf8-43f4-a9ba-95b3ade636a4",
    },
  },
  {
    name: "Rådet for sikker trafik: ",
    value: {
      type: "topscroll",
      guid: "d63c54ec-d4ac-43ce-8bd6-390d2aeaed89",
    },
  },
  {
    name: "Tv2 El Clasico",
    value: {
      type: "topscroll",
      guid: "e6bd9f0c-aff7-4f07-9bb4-ef370f5db701",
    },
  },
  {
    name: "Unilever Vegetarian Butcher",
    value: {
      type: "topscroll",
      guid: "f7632d96-ee28-40c0-a713-16282fd0f5c3",
    },
  },
  {
    name: "Wonder Woman (Sverige)",
    value: {
      type: "topscroll",
      guid: "47b5bc69-7022-4112-bc63-8755ee8932c4",
    },
  },
  {
    name: "Scanbox: Honest thief",
    value: {
      type: "topscroll",
      guid: "48f3237a-7cae-439c-a64e-ef1bcf0bd0de",
    },
  },
  {
    name: "BAT lyft Sverige: ",
    value: {
      type: "topscroll",
      guid: "91fc7dd8-62bf-41f8-8d69-9c11d3b33b22",
    },
  },
  {
    name: "Jeep	Topscroll:",
    value: {
      type: "topscroll",
      guid: "f6e24841-628f-4abd-8197-6629c17ec8b2",
    },
  },
  {
    name: "Norlys feb 2021",
    value: {
      type: "topscroll",
      guid: "e3987f6f-ae82-4be1-83f7-3fff3da7046e",
    },
  },
];

const interscrolls = [
  {
    name: "Imerco",
    value: {
      type: "interscroll",
      guid: "59a166b9-5462-4ebc-bf24-38f41e3b0310",
    },
  },
  {
    name: "BMW",
    value: {
      type: "interscroll",
      guid: "499aff42-c8ea-49e2-9f4a-a9bea70c41f6",
    },
  },
  {
    name: "Telia",
    value: {
      type: "interscroll",
      guid: "2a65f2e4-18dd-4d31-abcb-c111f7fd922a",
    },
  },
  {
    name: "Carlsberg",
    value: {
      type: "interscroll",
      guid: "c250f745-b35e-45d9-b035-1d8b6fdabc63",
    },
  },
];
const fullWidthInterscrolls = [
  {
    name: "Mad max",
    value: {
      type: "interscroll",
      guid: "4baac184-6daa-4dad-9407-d817f5489242",
    },
  },
  {
    name: "Audi",
    value: {
      type: "interscroll",
      guid: "6245f388-0f7f-4685-ade9-1b80a92a2007",
    },
  },
  {
    name: "Viaplay",
    value: {
      type: "interscroll",
      guid: "bec1b8ff-6734-4359-96ea-2e18bf33f561",
    },
  },
  {
    name: "Heineken",
    value: {
      type: "interscroll",
      guid: "07f2193f-290e-4695-9754-7b556cee7a2c",
    },
  },
];

const mobileInterscrolls = [
  {
    name: "IT",
    value: {
      type: "interscroll",
      guid: "d836edb6-2b87-4db4-a500-bae6e78cee6f",
    },
  },
  {
    name: "Treo",
    value: {
      type: "interscroll",
      guid: "146f763a-9e89-40d9-90e0-8e6fce4a3cd0",
    },
  },
  {
    name: "Oloffson biler",
    value: {
      type: "interscroll",
      guid: "61f272b4-0801-4fc2-9d55-d3321853d667",
    },
  },
  {
    name: "kop og kande",
    value: {
      type: "interscroll",
      guid: "2189d316-c49e-4315-a060-b667802d1a18",
    },
  },
  {
    name: "Arla food festival",
    value: {
      type: "interscroll",
      guid: "7d33e3f4-178f-46e9-b65e-1925470607dd",
    },
  },
  {
    name: "Scanbox De forbandede år",
    value: {
      type: "interscroll",
      guid: "dc4692b9-88bf-4607-9769-584c7df67f25",
    },
  },
  {
    name: "Gigaspeed",
    value: {
      type: "interscroll",
      guid: "5074beb8-72bf-4bae-84c0-9a6842134047",
    },
  },
  {
    name: "Mazda ",
    value: {
      type: "interscroll",
      guid: "359a858b-55e3-4c92-80be-82019d8d4f67",
    },
  },
  {
    name: "PS - days gone",
    value: {
      type: "interscroll",
      guid: "875d3100-67fa-46c3-9839-1d96cb2a0361",
    },
  },
  {
    name: "TV2 - mod fjerne kyster",
    value: {
      type: "interscroll",
      guid: "5a3d1a10-c5c8-49b4-8b8d-ddf15eeb01ab",
    },
  },
  {
    name: "Lego: ",
    value: {
      type: "interscroll",
      guid: "9349b212-f595-4ad7-a59b-53dd6db9b26f",
    },
  },
  {
    name: "Warner - Tenet",
    value: {
      type: "interscroll",
      guid: "f65e3828-db1e-46d6-892d-c2dd151a0b29",
    },
  },
  {
    name: "Tour de France TV2: ",
    value: {
      type: "interscroll",
      guid: "e7f78ce5-2e77-47f1-99f9-366a387dd807",
    },
  },
  {
    name: "P&G Braun barber: ",
    value: {
      type: "interscroll",
      guid: "26f4d70e-7bcc-416b-9f42-e849607a32a8",
    },
  },
  {
    name: "LEGO vehicles",
    value: {
      type: "interscroll",
      guid: "b4dc0d8d-a887-424b-9a8c-dd52842e24c9",
    },
  },
  {
    name: "BAT lyft sverige: ",
    value: {
      type: "interscroll",
      guid: "a2592dcd-f786-4c59-b1e1-4d4d1984caf8",
    },
  },
  {
    name: "Fanta ",
    value: {
      type: "interscroll",
      guid: "d99f12ac-92c1-4f9c-87c4-831eb905de98",
    },
  },
  {
    name: "TV2 El Clasico",
    value: {
      type: "interscroll",
      guid: "e36709d3-b093-43d8-8ac1-b412891b84fb",
    },
  },
  {
    name: "Unilever: ",
    value: {
      type: "interscroll",
      guid: "757a4526-b27c-4caa-973e-47c900c96f01",
    },
  },
  {
    name: "Yousee Gigaspeed",
    value: {
      type: "interscroll",
      guid: "5074beb8-72bf-4bae-84c0-9a6842134047",
    },
  },
  {
    name: "BAT lyft Sverige ",
    value: {
      type: "interscroll",
      guid: "f1d0d3f1-40b1-4256-b4b8-16121bae2a41",
    },
  },
  {
    name: "Jeep Interscroll:",
    value: {
      type: "interscroll",
      guid: "40ac8b45-0883-43ba-b0b6-f94170f82a54",
    },
  },
  {
    name: "Playstation Ghost of",
    value: {
      type: "interscroll",
      guid: "385c9fe8-9c47-4ccc-b798-8e6caef18475",
    },
  },
  {
    name: "TV2 play Stormester",
    value: {
      type: "interscroll",
      guid: "edc1e3c7-c8b4-4b55-ab5a-67ef37e1ed9f",
    },
  },
  {
    name: "Maria Casino: ",
    value: {
      type: "interscroll",
      guid: "d70bafe3-8afd-40e9-9ba7-61127d907294",
    },
  },
];
const expanders = [
  {
    name: "HBO",
    value: {
      type: "expander",
      guid: "c5160b69-9516-43ad-ae4a-e3a51a0b0f7c",
    },
  },
  {
    name: "Viaplay",
    value: {
      type: "expander",
      guid: "93dcbe3d-05bf-4d27-94c0-c9cbd8aa5cde",
    },
  },
  {
    name: "Cocio",
    value: {
      type: "expander",
      guid: "91b79009-8a54-479e-b52e-0973844f9d0a",
    },
  },
  {
    name: "Danske Bank",
    value: {
      type: "expander",
      guid: "458ca271-0532-4f9e-93d6-865988379bf6",
    },
  },
  {
    name: "Glad for Gris",
    value: {
      type: "expander",
      guid: "512d2aa4-153d-483f-8615-ec5329bc02a7",
    },
  },
  {
    name: "Schüco",
    value: {
      type: "expander",
      guid: "268f949b-ba3d-4f1f-98b1-e5f85e382426",
    },
  },
  {
    name: "Multi Tabs",
    value: {
      type: "expander",
      guid: "c856f36f-f1e3-4265-90ac-2e1f641af3e8",
    },
  },
  {
    name: "Oister",
    value: {
      type: "expander",
      guid: "12beec40-07a5-4579-9259-2fc5759e2418",
    },
  },
  {
    name: "Oister",
    value: {
      type: "expander",
      guid: "6a83f78c-e2f9-428d-bc59-2e277a3ef6f0",
    },
  },
  {
    name: "Skagerrak Gin (Arcus)",
    value: {
      type: "expander",
      guid: "d7a1e1a6-1211-4bed-a5dd-4eceacb318bf",
    },
  },
];

module.exports = {
  expanders,
  mobTopScrolls,
  mobMidscrolls,
  skins,
  topscrolls,
  interscrolls,
  fullWidthInterscrolls,
  mobileInterscrolls,
  mobAchors,
  deskTakeover
};

import { render, staticRenderFns } from "./articleFeature.vue?vue&type=template&id=a512a58a&scoped=true&"
import script from "./articleFeature.vue?vue&type=script&lang=js&"
export * from "./articleFeature.vue?vue&type=script&lang=js&"
import style0 from "./articleFeature.vue?vue&type=style&index=0&id=a512a58a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a512a58a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardText,VCol,VRow,VSheet,VSkeletonLoader})

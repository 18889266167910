<template>
  <v-footer
    style="border-top: 1px solid; border-color: lightgrey;"
    fixed
    :padless="true"
  >
    <v-fab-transition>
      <v-btn
        v-show="!showFooter"
        @click="showMe"
        color="pink"
        dark
        small
        absolute
        top
        right
        fab
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-card class="flex pa-4" width="100%" flat tile>
      <v-row align="center" justify="center" v-if="showFooter" dense>
        <v-tabs v-if="showTemplates" hide-slider color="#81cac7" centered>
          <div v-if="!$vuetify.breakpoint.mdAndUp" style="padding-top: 5px">
            <div class="float-right">
              <v-btn @click="emitToogleColor" icon>
                <v-icon>mdi-cursor-default-click-outline</v-icon>
              </v-btn>

              <v-btn @click="hide" text>
                HIDE
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </div>
          </div>
          <v-tab
            v-for="(type, index) in toggle_exclusive === 0
              ? desktopTypes
              : mobileTypes"
            :key="index"
          >
            <v-menu offset-y open-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#81cac7" v-bind="attrs" v-on="on" text>
                  {{ type.name }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  dense
                  v-for="(item, index) in type.templates"
                  :key="index"
                >
                  <v-btn @click="changeTemplate(item)" small text>{{
                    item.name
                  }}</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tab>
        </v-tabs>

        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-img max-width="70" contain src="./../assets/logo.png" />
        </v-col>
        <v-col>
          <v-tabs
            @change="emitDevice"
            v-model="toggle_exclusive"
            centered
            color="#45cfcb"
            hide-slider
            icons-and-text
          >
            <v-tab
              @click="showTemplatesHover"
              v-for="(icon, index) in icons"
              :key="icon.name"
            >
              <img
                v-bind:src="
                  require('./../assets/' +
                    icon.name +
                    `${
                      index === toggle_exclusive ? '-green.png' : '-black.png'
                    }`)
                "
                :max-width="icon.width"
                :alt="icon.name"
              />
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown" style="padding-top: 5px">
          <div class="float-right">
            <!-- <v-btn @click="emitToogleColor" icon>
              <v-icon>mdi-cursor-default-click-outline</v-icon>
            </v-btn> -->

            <v-btn @click="hide" text>
              HIDE
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
const {
  expanders,
  mobTopScrolls,
  mobMidscrolls,
  skins,
  mobAchors,
  deskTakeover,
  topscrolls,
  interscrolls,
  fullWidthInterscrolls,
} = require("../helpers/creatives");
export default {
  name: "custom-footer",
  data: () => ({
    hidden: false,
    showFooter: false,
    toggle_exclusive: 0,
    showTemplates: false,
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    desktopTypes: [
      { name: "Topscrolls", templates: topscrolls },
      { name: "Skins", templates: skins },
      { name: "Interscrolls", templates: interscrolls },
      { name: "Expanders", templates: expanders },
      { name: "Full width interscroll", templates: fullWidthInterscrolls },
      { name: "Takeovers", templates: deskTakeover },
    ],
    mobileTypes: [
      { name: "Topscrolls", templates: mobTopScrolls },
      { name: "mobMidscrolls", templates: mobMidscrolls },
      { name: "mobAchors", templates: mobAchors },
    ],
    icons: [
      { name: "monitor", contain: "33px" },
      { name: "mobile-phone", width: "16px" },
    ],
  }),
  watch: {
    isMobile: {
      immediate: true,
      handler(newVal) {
        this.toggle_exclusive = newVal === true ? 1 : 0;
      },
    },
  },
  methods: {
    changeTemplate(template) {
      window.location.href = `${window.location.pathname}?${template.value.type}&guid=${template.value.guid}`;
    },

    emitToogleColor() {
      this.$emit("toogleColor");
    },
    emitDevice() {
      let device = this.icons[this.toggle_exclusive].name;
      this.$emit("deviceChange", device);
    },
    showTemplatesHover() {
      this.showTemplates = true;
    },
    hide() {
      this.showFooter = false;
    },
    showMe() {
      this.showFooter = true;
    },
  },
  props: {
    isMobile: { type: Boolean, default: false },
  },
};
</script>

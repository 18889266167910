<template>
  <v-card tile class="my-4" flat>
    <v-row class="px-4">
      <v-col cols="12">
        <v-skeleton-loader
          v-if="withColor"
          boilerplate
          class="mx-auto"
          max-width="70vw"
          type="image"
        ></v-skeleton-loader>
        <!-- <v-img
          height="50vh"
          v-if="withColor"
          v-bind:src="require('./../assets/dummy_placeholder.png')"
        ></v-img> -->
        <v-sheet v-else :height="600"></v-sheet>
      </v-col>
     
         <v-col cols="12">
          <div class="text-large hc_blue"></div>
         </v-col>
      
      <v-col :style="getStyle()" cols="12" md="6" >
        <v-sheet height="100%">
          
          <v-card-text>
           
           <div class="text-sub long-70 hc_grey grey"></div>
          <div class="text-sub long-85 hc_grey grey"></div>
          <div class="text-sub long-75 hc_grey grey"></div>
          <div class="text-sub long-90 hc_grey grey"></div>
          <div class="text-sub long-75 hc_grey grey"></div>
          <div class="text-sub long-65 hc_grey grey"></div>
          </v-card-text>
        </v-sheet>
      </v-col>
       <v-col :style="getStyle()" cols="12" md="6">
        <v-sheet height="100%">
          <v-card-text>
          
           <div class="text-sub long-70 hc_grey grey"></div>
          <div class="text-sub long-85 hc_grey grey"></div>
          <div class="text-sub long-75 hc_grey grey"></div>
          <div class="text-sub long-90 hc_grey grey"></div>
          <div class="text-sub long-75 hc_grey grey"></div>
          <div class="text-sub long-65 hc_grey grey"></div>
          </v-card-text>
        </v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
const { fakeSentece, fakeTime } = require("./../helpers/faker");
export default {
  name: "article-feature",
  created() {
    this.text = fakeSentece(16);
    this.title = fakeSentece(4);
    this.timeAgo = fakeTime(60);
  },
  methods: {
    getStyle() {
      let style = {
        "padding-left": this.isMobileFormat ? "12px" : "0px",
        "padding-right": this.isMobileFormat ? "12px" : "0px",
        "margin-left": this.isMobileFormat ? null : "-12px",
        "margin-top": this.isMobileFormat ? "-25px" : null,
      };
      return style;
    },
  },
  props: {
    withColor: { type: Boolean, default: true },
    isMobileFormat: { type: Boolean, default: false },
    img: { type: String },
  },
  data: () => ({
    text: "",
    title: "",
    label: "LABEL",
    timeAgo: "",
  }),
};
</script>
<style scoped>
p.title-feature {
  color: #101010;
  font-family: Poppins;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 76px;
}

.hc_grey {
	background: #ddd!important;
}

.hc_blue{
  background: #99d7de;
}

</style>

<template>
  <v-card :color="getBackground()" tile class="text-justify" flat>
    <v-skeleton-loader
    class="px-3"
          v-if="withColor"
          boilerplate
          
          type="image"
        ></v-skeleton-loader>
    <!-- <v-img
      v-if="withColor"
      :height="imgHeight"
      width="88vw"
      v-bind:src="require('./../assets/dummy_placeholder.png')"
    ></v-img> -->
    <v-sheet v-else :height="imgHeight"></v-sheet>

    <div v-if="big">
      <v-col cols="12">
        <div class="headline long-70" style="background: #99d7de"></div>

        <div class="text-sub long-70 hc_grey"></div>
        <div class="text-sub long-85 hc_grey"></div>
        <div class="text-sub long-75 hc_grey"></div>
        <div class="text-sub long-90 hc_grey"></div>
        <div class="text-sub long-75 hc_grey"></div>
        <div class="text-sub long-65 hc_grey"></div>
        <div class="text-sub long-75 hc_grey"></div>
        <div class="text-sub long-90 hc_grey"></div>
        <div class="text-sub long-75 hc_grey"></div>
        <div class="text-sub long-65 hc_grey"></div>
        <div class="text-sub long-90 hc_grey"></div>

        
      </v-col>
      <v-row class="pt-9">
        <v-col>
         <v-skeleton-loader
          class="px-3"
          v-if="withColor"
          boilerplate
          
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col>
         <v-skeleton-loader
          class="px-3"
          v-if="withColor"
          boilerplate
         
          type="card"
        ></v-skeleton-loader>
      </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-card-text style="padding: 0">
        <v-col cols="12">
          <div class="headline long-70" style="background: #99d7de"></div>

          <div class="text-sub long-70 hc_grey"></div>
          <div class="text-sub long-85 hc_grey"></div>
          <div class="text-sub long-75 hc_grey"></div>
          <div class="text-sub long-90 hc_grey"></div>
          <div class="text-sub long-75 hc_grey"></div>
          <div class="text-sub long-65 hc_grey"></div>
        </v-col>
        

        <!-- <div
          :class="
            big ? 'big-text float-left text-justify' : 'float-left text-justify'
          "
        >
          {{ text }}
        </div> -->
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
const { fakeSentece, fakeTime } = require("./../helpers/faker");
export default {
  name: "article-preview",
  created() {
    this.text = fakeSentece(9);
    this.title = fakeSentece(2);
    this.timeAgo = fakeTime(60);
  },
  props: {
    withColor: { type: Boolean, default: true },
    img: { type: String },
    imgHeight: { type: String },
    background: { type: String },
    big: { type: Boolean, default: false },
  },
  data: () => ({
    text: "",
    title: "",
    label: "LABEL",
    timeAgo: "",
  }),
  methods: {
    getBackground() {
      if (this.big && this.withColor) return this.background;
      if (this.big && !this.withColor) return "#e6e6e6";
    },
  },
};
</script>
<style scoped>
.big-title {
  color: #101010;
  font-family: Poppins;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 76px;
}

.hc_grey {
	background: #ddd!important;
}
</style>

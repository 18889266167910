<template>
  <v-card max-height="200" tile class="my-0" flat>
    <v-row>
      <v-col v-if="!rigth" cols="12" md="4">
        <v-skeleton-loader
        boilerplate
          v-if="withColor"
          class="mx-auto"
          max-width="500"
          type="image"
        ></v-skeleton-loader>
        <!-- <v-img
          v-if="withColor"
          :height="imgHeight"
          v-bind:src="require('./../assets/dummy_placeholder.png')"
        ></v-img> -->
        <v-sheet v-else :height="imgHeight"></v-sheet>
      </v-col>

      <v-col cols="12" md="8">
        <v-row>
          <!-- <v-col>
            <div class="subtitle-2 float-left">
              {{ label }}
            </div>
          </v-col> -->
          <!-- <v-col>
            <div class="subtitle-2 float-right">{{ timeAgo }} min siden</div>
          </v-col> -->
        </v-row>
        <v-col cols="12" class="mt-3">
          <div class="headline long-70" style="background: #99d7de"></div>
        </v-col>

        <v-card-text>
          <div class="text-sub long-70 hc_grey grey"></div>
          <div class="text-sub long-85 hc_grey grey"></div>
          <div class="text-sub long-75 hc_grey grey"></div>
          <div class="text-sub long-90 hc_grey grey"></div>
          <div class="text-sub long-75 hc_grey grey"></div>
          <div class="text-sub long-65 hc_grey grey"></div>
        </v-card-text>
      </v-col>
      <v-col v-if="rigth" cols="12" md="4">
        <v-skeleton-loader
        boilerplate
          v-if="withColor"
          class="mx-auto"
          max-width="500"
          type="image"
        ></v-skeleton-loader>
        <!-- <v-img
          v-if="withColor"
          :height="imgHeight"
          v-bind:src="require('./../assets/dummy_placeholder.png')"
        ></v-img> -->
        <v-sheet v-else :height="imgHeight"></v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
const { fakeSentece, fakeTime } = require("../helpers/faker");
export default {
  name: "full-width-feature",
  created() {
    this.text = fakeSentece(16);
    this.title = fakeSentece(4);
    this.timeAgo = fakeTime(60);
  },
  props: {
    withColor: { type: Boolean, default: true },

    img: { type: String, required: true },
    rigth: { type: Boolean, default: false },
    imgHeight: { type: String, default: "200" },
  },
  data: () => ({
    text: "",
    title: "",
    label: "LABEL",
    timeAgo: "",
  }),
};
</script>
<style scoped>
p.title-feature {
  color: #101010;
  font-family: Poppins;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
}

.hc_grey {
  background: #ddd !important;
}
</style>

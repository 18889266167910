<template>
  <div>
    <div v-if="show" id="cncpt-desktop-view">
      <div id="cncpt-wrap">
      <div id="cncpt-content">
        <div id="cncpt-iphone">
          <div id="cncpt-iphone-viewport">
            <div id="cncpt-iphone-banner">
              <div id="cncpt-iphone-screen">
                <slot></slot>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <slot v-if="!show"></slot>
  </div>
</template>

<script>
export default {
  name: "mobile-view",
  props: {
    show: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss">

div#cncpt-desktop-view {
  height: 100vh;
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(92, 86, 90, 0.73)), url("../assets/cph_nyhavn.jpg");
  background-size: cover;
  background-color: currentcolor;
  background-position: center;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticStyle:{"border-top":"1px solid","border-color":"lightgrey"},attrs:{"fixed":"","padless":true}},[_c('v-fab-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFooter),expression:"!showFooter"}],attrs:{"color":"pink","dark":"","small":"","absolute":"","top":"","right":"","fab":""},on:{"click":_vm.showMe}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1),_c('v-card',{staticClass:"flex pa-4",attrs:{"width":"100%","flat":"","tile":""}},[(_vm.showFooter)?_c('v-row',{attrs:{"align":"center","justify":"center","dense":""}},[(_vm.showTemplates)?_c('v-tabs',{attrs:{"hide-slider":"","color":"#81cac7","centered":""}},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticStyle:{"padding-top":"5px"}},[_c('div',{staticClass:"float-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.emitToogleColor}},[_c('v-icon',[_vm._v("mdi-cursor-default-click-outline")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.hide}},[_vm._v(" HIDE "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]):_vm._e(),_vm._l((_vm.toggle_exclusive === 0
            ? _vm.desktopTypes
            : _vm.mobileTypes),function(type,index){return _c('v-tab',{key:index},[_c('v-menu',{attrs:{"offset-y":"","open-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#81cac7","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(type.name)+" ")])]}}],null,true)},[_c('v-list',_vm._l((type.templates),function(item,index){return _c('v-list-item',{key:index,attrs:{"dense":""}},[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.changeTemplate(item)}}},[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1)})],2):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',[_c('v-img',{attrs:{"max-width":"70","contain":"","src":require("./../assets/logo.png")}})],1):_vm._e(),_c('v-col',[_c('v-tabs',{attrs:{"centered":"","color":"#45cfcb","hide-slider":"","icons-and-text":""},on:{"change":_vm.emitDevice},model:{value:(_vm.toggle_exclusive),callback:function ($$v) {_vm.toggle_exclusive=$$v},expression:"toggle_exclusive"}},_vm._l((_vm.icons),function(icon,index){return _c('v-tab',{key:icon.name,on:{"click":_vm.showTemplatesHover}},[_c('img',{attrs:{"src":require('./../assets/' +
                  icon.name +
                  "" + (index === _vm.toggle_exclusive ? '-green.png' : '-black.png')),"max-width":icon.width,"alt":icon.name}})])}),1)],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{staticStyle:{"padding-top":"5px"}},[_c('div',{staticClass:"float-right"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.hide}},[_vm._v(" HIDE "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }